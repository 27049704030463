import React, { useState, useCallback, useEffect } from "react";
import "@5tbim/utils/i18n";
import { useTranslation } from "react-i18next";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Notifications from "react-notify-toast";
import "../styles/common.scss";
import "../polyfill/canvas-to-blob";
import "../polyfill/element-remove";
export default function MainLayout({ children, showFooter = true, navKey }) {
  const { i18n } = useTranslation();
  const onLangChange = useCallback(
    (lang) => {
      i18n.changeLanguage(lang);
    },
    [i18n]
  );

  return (
    <div>
      <div id="header">
        <Header onLangChange={onLangChange} navKey={navKey} />
      </div>
      <div id="body">{children}</div>
      {showFooter && (
        <div id="footer">
          <Footer />
        </div>
      )}
      <Notifications />
    </div>
  );
}
