import React, { useState, useEffect } from "react";
import moment from "moment";
import { fetch } from "@5tbim/services/researchProject";
import Config from "@5tbim/config";

function List({ contentStyle = {} }) {
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    (async () => {
      const pagination = {
        pageIndex: 1,
        pageSize: 3,
        orderby: [
          ["orderIndex", "desc"],
          ["requestStartDate", "desc"],
        ],
      };
      const response = await fetch({
        pagination,
      });
      if (response) {
        setDatas(response.data.rows);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      {datas && datas.length
        ? datas.map((data) => {
            return (
              <div className="wrapcontainer__row__col">
                <div className="contentItem">
                  <div className="contentItem__img">
                    <a
                      href={Config.ResearchProjectDetail + "?id=" + data.id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt=""
                        src={
                          data.attachments && data.attachments.length
                            ? data.attachments[0].uri
                            : "images/fa1.png"
                        }
                      ></img>
                    </a>
                  </div>
                  <div className="contentItem__content" style={contentStyle}>
                    <div className="contentItem__content__title">
                      <a
                        href={Config.ResearchProjectDetail + "?id=" + data.id}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data.title}
                      </a>
                    </div>
                    <div className="contentItem__content__subtitle">
                      发起方：
                      {data.organization.name1
                        ? data.organization.name1 + "/" + data.organization.name
                        : data.organization.name}
                    </div>
                    <div className="contentItem__content__subtitle">
                      报名时间：
                      {moment(data.requestStartDate).format("YYYY.MM.DD")}-
                      {moment(data.requestEndDate).format("YYYY.MM.DD")}
                    </div>
                    {/* <div
                      className="contentItem__content__desc text"
                      dangerouslySetInnerHTML={{
                        __html: data.desc.replace(/\n/gi, "<br/>"),
                      }}
                    ></div> */}
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </React.Fragment>
  );
}

export default List;
