import Config from "@5tbim/config";
import request from "@5tbim/utils/request";
const HOST = Config.HOST;

export async function fetchActivities(params) {
  return request(
    `${HOST}/api/academicact/list`,
    {
      method: "POST",
      body: params,
      expirys: true,
    },
    true
  );
}

export async function fetchActivityDetail(params) {
  return request(
    `${HOST}/api/academicact/${params.id}`,
    {
      method: "GET",
      expirys: true,
    },
    true
  );
}

export async function myActivities(params) {
  return request(
    `${HOST}/client/academicattend/userAttends`,
    {
      method: "GET",
      expirys: true,
    },
    true
  );
}

export async function fetchActivityAttendDetail(params) {
  return request(
    `${HOST}/client/academicattend/${params.id}`,
    {
      method: "GET",
      expirys: true,
    },
    true
  );
}

export async function submit(params) {
  return request(`${HOST}/client/academicattach/create`, {
    body: params,
    method: "POST",
  });
}

export async function deleteAttach(params) {
  return request(`${HOST}/client/academicattach/${params}/delete`, {
    method: "DELETE",
  });
}
