import Config from "@5tbim/config";
import request from "@5tbim/utils/request";
const HOST = Config.HOST;

export async function fetchResources(params) {
  return request(
    `${HOST}/api/resources/list`,
    {
      method: "POST",
      body: params,
    },
    true
  );
}

export async function fetchResourcesDetail(params) {
  return request(
    `${HOST}/api/resources/${params.id}`,
    {
      method: "GET",
    },
    true
  );
}

export async function updateResourceContent(params) {
  return request(
    `${HOST}/client/researchSubjectRequestphases/outputs/${params.outputId}/updateResourceContent`,
    {
      method: "PUT",
      body: params,
    },
    true
  );
}

export async function getResourceContent(params) {
  return request(
    `${HOST}/client/resources/${params.id}`,
    {
      method: "GET",
    },
    true
  );
}
