export default {
  OssAuthAddress: {
    OssAuth: "/getOssAuth",
    createVodUploadAuth: "/createVodUploadAuth",
    refreshVodUploadAuth: "/refreshVodUploadAuth",
    attachmentSavePath: "/attachments",
  },

  OssConfig: {
    region: "oss-cn-beijing",
    endpoint: "oss-cn-beijing.aliyuncs.com",
    bucket: "saiwill-img",
  },

  AttachmentApi: {
    save: "/attachments",
    delete: "/attachments/:attachmentId",
  },
};
