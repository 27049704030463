import React from "react";
import ReactDOM from "react-dom";
import Config from "@5tbim/config";
import ExamInformations from "./components/examInformations";
import Resources from "./components/resources";
import ResearchProjectList from "./components/researchProjects";
import TrainClassesList from "./components/trainClasses";
import CarouselImage from "./components/CarouselImage";
import RowContainer from "./components/RowContainer";
import MainLayout from "../mainLayout";
import ExpertContainer from "./components/expertContainer";
import Questionnaires from "./components/questionnaires";
import Activitys from "./components/academicactivity";
import "./components/row.scss";
import "./components/expert.scss";
import "./index.scss";

function IndexPage() {
  return (
    <MainLayout navKey="main">
      <CarouselImage />
      <RowContainer
        title="趋势研究"
        rowStyle={{ background: "#e0e0e0" }}
        more={
          <a
            href={Config.QuestionnaireList}
            target="_blank"
            rel="noopener noreferrer"
          >
            更多>>
          </a>
        }
      >
        <Questionnaires contentStyle={{ height: 140 }} />
      </RowContainer>
      <RowContainer
        title="科研课题"
        rowStyle={{ background: "#f3f1ec" /*background: "#f5f5f5"*/ }}
        more={
          <a
            href={Config.ResearchProjects}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#2F72A8", textDecoration: "underline" }}
          >
            更多>>
          </a>
        }
      >
        <ResearchProjectList />
        <div
          class="wrapcontainer__row__col"
          style={{
            background: "#e0e0e0",
            marginTop: "10px",
            marginLeft: "auto",
          }}
        >
          <div class="contentItem" style={{ marginTop: "-90px" }}>
            <div class="contentItem__content" style={{ height: "auto" }}>
              <div
                class="contentItem__content__title"
                style={{
                  fontSize: "28px",
                  fontWeight: "normal",
                  marginBottom: "40px",
                }}
              >
                校企合作课题支持
              </div>
              <div style={{ padding: "10px 10px" }}>
                <div
                  class="contentItem__content__subtitle"
                  style={{ fontSize: "14px", textIndent: "24px" }}
                >
                  采薇君华2021年将以《5TBIM教学支持平台》和《中英文BIM教育资源》继续支持建筑类高校的BIM人才培养和相关专业教学改革，并邀请建筑类本科和高职院校参与校企合作的《智慧建造人才培养内容和模式创新》课题研究。我公司将邀请国内外智慧建造专家指导科研活动，并为本课题申报院校提供价值38万元的《5T数字建筑科教服务平台》一套。
                  <br />
                  请进入《智慧建造人才培养内容和模式创新》课题，提交申请。
                </div>
              </div>
            </div>
          </div>
        </div>
      </RowContainer>
      <RowContainer
        title="学术活动"
        rowStyle={{ background: "#e0e0e0" }}
        more={
          <a
            href={Config.AcademicList}
            target="_blank"
            rel="noopener noreferrer"
          >
            更多>>
          </a>
        }
      >
        <Activitys />
      </RowContainer>

      <RowContainer
        title="培训项目"
        rowStyle={{
          background: "#e0e0e0",
        }}
        more={
          <a
            href={Config.TrainClasses}
            target="_blank"
            rel="noopener noreferrer"
          >
            更多>>
          </a>
        }
      >
        <TrainClassesList />
        <div
          class="wrapcontainer__row__col"
          style={{
            background: "#f3f1ec",
            marginTop: "10px",
            marginLeft: "auto",
          }}
        >
          <div class="contentItem" style={{ marginTop: "-90px" }}>
            <div class="contentItem__content" style={{ height: "auto" }}>
              <div
                class="contentItem__content__title"
                style={{
                  fontSize: "28px",
                  fontWeight: "normal",
                  marginBottom: "40px",
                }}
              >
                培训师申请
              </div>
              <div style={{ padding: "20px 20px" }}>
                <div
                  class="contentItem__content__subtitle"
                  style={{ fontSize: "14px", textIndent: "24px" }}
                >
                  具备三年以上BIM应用经验，并获得采薇君华系列BIM培训证书的优秀从业人员，可申请成为BIM培训师。欢迎具备资格的优秀人才递交BIM培训师申请，我们将尽快与您联系。
                  <br />
                  咨询电话：18612869911
                </div>
                <div
                  class="contentItem__content__subtitle"
                  style={{ margin: "15px 0 10px 0", textAlign: "center" }}
                >
                  <a
                    className="button bg-blue"
                    style={{
                      height: "30px",
                      width: "160px",
                      display: "inline-block",
                      lineHeight: "30px",
                      margin: "10px 0",
                      fontSize: "16px",
                      borderRadius: 0,
                      textDecoration: "none",
                      marginTop: "30px",
                    }}
                    href={`${Config.UserCenter}${Config.RequestTrainer}`}
                  >
                    申请成为培训师
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RowContainer>

      <div
        style={{
          background: 'url("images/exam-bg.jpg")',
          backgroundSize: "cover",
          color: "#fff",
          display: "flex",
          height: "400px",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            flexShrink: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ margin: "30px 0 0 90px", height: "100px" }}>
            <img
              src="images/exam-label.png"
              style={{ width: "78%", height: "auto" }}
            ></img>
            <div
              class="contentItem__content__subtitle"
              style={{ margin: "5px 0 10px 240px" }}
            >
              <a
                style={{
                  background: "#2196f3",
                  height: "30px",
                  width: "160px",
                  display: "inline-block",
                  lineHeight: "30px",
                  margin: "10px 0",
                  fontSize: "16px",
                  borderRadius: 24,
                  textDecoration: "none",
                  marginTop: "10px",
                  color: "#fff",
                  textAlign: "center",
                }}
                href={Config.UserRequestList}
              >
                考生登录
              </a>
            </div>
          </div>

          <ExamInformations />
        </div>
        <div style={{ width: "300px", flexGrow: 0, flexShrink: 0, margin: 20 }}>
          <img
            src="images/exam-qcode.png"
            style={{ width: "200px", height: "360px" }}
          ></img>
        </div>
      </div>

      <div style={{ display: "flex" }} className="resource">
        <RowContainer
          title="专业文献"
          rowStyle={{ background: "#e0e0e0", width: "50%" }}
          more={
            <a
              href={Config.ResourceList + "?type=infomation"}
              target="_blank"
              rel="noopener noreferrer"
            >
              更多>>
            </a>
          }
        >
          <Resources resourceType="infomation" />
        </RowContainer>
        <RowContainer
          title="行业动态"
          rowStyle={{ background: "#dad9d9", width: "50%" }}
          more={
            <a
              href={Config.ResourceList + "?type=newsCategroy"}
              target="_blank"
              rel="noopener noreferrer"
            >
              更多>>
            </a>
          }
        >
          <Resources resourceType="newsCategroy" />
        </RowContainer>
      </div>

      {/* <RowContainer title="专家导师" rowStyle={{ background: "#f3f1ec" }}>
        <ExpertContainer />
      </RowContainer> */}
    </MainLayout>
  );
}

ReactDOM.render(<IndexPage />, document.getElementById("root"));

if (module.hot) {
  module.hot.accept();
}
