import * as fetch from "isomorphic-fetch";
import hash from "hash.js";
import Config from "@5tbim/config";
import { notify } from "react-notify-toast";
import {
  cleanCurrentUserStore,
  getCurrentUserInfo,
  getCurrentUserToken,
} from "./userStore";

const codeMessage = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。",
};

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  // 系统错误
  const errortext =
    response.message || codeMessage[response.status] || response.statusText;
  if (response.status !== 401) {
    notify.show(errortext, "error", 2000);
  }
  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  //如果是认证授权错误，删除当前的用户存储
  if (response.status === 401) {
    cleanCurrentUserStore();
  }
  throw error;
};

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [option] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
async function request(url, option, noNeedToken) {
  if (!/^https?:\/\//.test(url)) {
    url = Config.HOST + url;
  }
  const options = {
    ...option,
  };
  /**
   * Produce fingerprints based on url and parameters
   * Maybe url has the same parameters
   */
  const fingerprint = url + (options.body ? JSON.stringify(options.body) : "");
  const hashcode = hash.sha256().update(fingerprint).digest("hex");

  const defaultOptions = {
    //credentials: 'include',
  };

  const newOptions = {
    ...defaultOptions,
    ...options,
  };
  if (
    newOptions.method === "POST" ||
    newOptions.method === "PUT" ||
    newOptions.method === "DELETE" ||
    newOptions.method === "GET"
  ) {
    const userInfo = getCurrentUserInfo() || {};
    const token = getCurrentUserToken();
    if (!noNeedToken && !token) {
      //notify.show("登录过期，请重新登录", "error", 2000);
      return;
    }
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        ...newOptions.headers,
        jwtToken: token,
        username: userInfo.talentNo,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: "application/json",
        ...newOptions.headers,
        jwtToken: token,
        username: userInfo.talentNo,
      };
    }
  }
  const expirys = options.expirys && 60;
  // options.expirys !== false, return the cache,
  if (options.expirys !== false) {
    const cached = sessionStorage.getItem(hashcode);
    const whenCached = sessionStorage.getItem(`${hashcode}:timestamp`);
    if (cached !== null && whenCached !== null) {
      const age = (Date.now() - whenCached) / 1000;
      if (age < expirys) {
        const response = new Response(new Blob([cached]));
        return response.json();
      }
      sessionStorage.removeItem(hashcode);
      sessionStorage.removeItem(`${hashcode}:timestamp`);
    }
  }
  try {
    const response = await fetch(url, newOptions);
    await checkStatus(response);
    if (options.expirys !== false) {
    }
    const json = await response.json();
    if (json.code !== "0") {
      notify.show(json.message, "error", 3000);
      return;
    }
    return json;
  } catch (e) {}
}

export async function download(file, progressCb) {
  const requrl = file.url.replace(/http:\/\//gi, "https://");
  const response = await fetch(requrl, { method: "GET", mode: "cors" });
  const blob = await response.blob();
  if ("msSaveOrOpenBlob" in navigator) {
    window.navigator.msSaveOrOpenBlob(blob, file.name);
    progressCb && progressCb({ done: true });
    return;
  }
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = file.name;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove(); // afterwards we remove the element again
}

export default request;
