import React, { useCallback } from "react";
import { cleanCurrentUserStore } from "@5tbim/utils/userStore";

function UserInfo({ userInfo, onUserClick }) {
  const onExit = useCallback(() => {
    cleanCurrentUserStore();
    window.location.reload();
  }, []);

  return (
    <React.Fragment>
      <span
        onClick={onUserClick}
        style={{ cursor: "pointer", textDecoration: "underline" }}
      >
        {userInfo.name}
      </span>
      <span
        style={{
          marginLeft: "15px",
          color: "#fff",
          fontSize: 14,
          cursor: "pointer",
          textDecoration: "underline",
        }}
        onClick={onExit}
      >
        安全退出
      </span>
    </React.Fragment>
  );
}

export default UserInfo;
