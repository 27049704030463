import React, { useState, useEffect } from "react";
import { fetchResources } from "@5tbim/services/resources";
import Config from "@5tbim/config";

function List({ contentStyle }) {
  const [resources, setResources] = useState();

  useEffect(() => {
    (async () => {
      const pagination = { pageIndex: 1, pageSize: 3 };
      const response = await fetchResources({
        query: {
          type: "newsCategroy",
          author_user_id: "bf57ac0b-e090-4dd7-be12-bfc37b01499e",
        },
        pagination,
      });
      if (response) {
        setResources(response.data.rows);
      }
    })();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        margin: "120px 142px 80px 90px",
        justifyContent: "space-between",
      }}
    >
      {resources && resources.length
        ? resources.map((resource) => {
            return (
              <div style={{ width: "220px" }}>
                <a
                  rel="noopener noreferrer"
                  href={Config.ResourceDetail + "?id=" + resource.id}
                  target="_blank"
                >
                  <img src={resource.logo} style={{ width: "100%" }}></img>
                </a>
              </div>
            );
          })
        : null}
    </div>
  );
}

export default List;
