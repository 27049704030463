import React from "react";

export default function ({ title, children, rowStyle, more }) {
  return (
    <div className="wrapcontainer" style={rowStyle}>
      <div className="wrapcontainer__head">
        <span className="wrapcontainer__head__title">{title}</span>
        <span className="wrapcontainer__head__more">{more}</span>
      </div>
      <div className="wrapcontainer__row">{children}</div>
    </div>
  );
}
