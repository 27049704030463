import React, { useCallback } from "react";
import Config from "@5tbim/config";
import UserInfo from "../../components/UserInfo";
import { getCurrentUserInfo } from "@5tbim/utils/userStore";
import "./index.scss";

export default function Header({ onLangChange, navKey }) {
  const enterUserCenter = useCallback(() => {
    window.location.href = Config.UserCenter;
  });

  const userInfo = getCurrentUserInfo();

  return (
    <div className="headNav" style={{ display: "flex", padding: "0 0 0 20px" }}>
      <div className="logo">
        <img src="images/saiwilllogo.png"></img>
      </div>
      <div>
        <ul className="menu">
          <li className={navKey === "main" ? "selected" : ""}>
            <a
              href={Config.Main}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              首页
            </a>
          </li>
          <li className={navKey === "questionnaireList" ? "selected" : ""}>
            <a
              href={Config.QuestionnaireList}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              趋势研究
            </a>
          </li>
          <li className={navKey === "reseachProjects" ? "selected" : ""}>
            <a
              href={Config.ResearchProjects}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              科研课题
            </a>
          </li>
          <li className={navKey === "academic" ? "selected" : ""}>
            <a
              href={Config.AcademicList}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              学术活动
            </a>
          </li>
          <li className={navKey === "train" ? "selected" : ""}>
            <a
              href={Config.TrainClasses}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              培训项目
            </a>
          </li>
          <li className={navKey === "examInfomation" ? "selected" : ""}>
            <a
              href={Config.ResourceList + "?type=examInfomation"}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              能力评价
            </a>
          </li>
          <li className={navKey === "resources" ? "selected" : ""}>
            <a
              className="hassubmenu"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              文献&nbsp;&&nbsp;资讯
            </a>
            <ul className="submenu">
              <li>
                <a
                  href={Config.ResourceList + "?type=infomation"}
                  rel="noopener noreferrer"
                >
                  专业文献
                </a>
              </li>
              <li>
                <a
                  href={Config.ResourceList + "?type=newsCategroy"}
                  rel="noopener noreferrer"
                >
                  行业资讯
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div style={{ marginLeft: "auto", textAlign: "right", padding: "0 5px" }}>
        <ul className="menu">
          {/* <li>
            <select onChange={(e) => onLangChange(e.target.value)}>
              <option value="zh">中文</option>
              <option value="en">英文</option>
            </select>
          </li> */}
          {userInfo ? (
            <li>
              <UserInfo userInfo={userInfo} onUserClick={enterUserCenter} />
            </li>
          ) : (
            <li
              onClick={enterUserCenter}
              style={{
                margin: "0 10px",
              }}
              className={navKey === "usercenter" ? "selected" : ""}
            >
              用户中心
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
