import React, { useState, useEffect } from "react";
import { fetchResources } from "@5tbim/services/resources";
import Config from "@5tbim/config";

export default function (props) {
  const { resourceType } = props;

  const [resources, setResources] = useState([]);

  useEffect(() => {
    (async () => {
      const pagination = { pageIndex: 1, pageSize: 6 };
      const response = await fetchResources({
        query: {
          type: resourceType,
          ne$$author_user_id: "bf57ac0b-e090-4dd7-be12-bfc37b01499e",
        },
        pagination,
      });
      if (response) {
        setResources(response.data.rows);
      }
    })();
  }, [resourceType]);

  useEffect(() => {}, []);

  return (
    <ul>
      {resources.map((resource) => {
        return (
          <li key={resource.id}>
            <a
              rel="noopener noreferrer"
              href={Config.ResourceDetail + "?id=" + resource.id}
              target="_blank"
            >
              {resource.title}
            </a>
          </li>
        );
      })}
    </ul>
  );
}
