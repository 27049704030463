import React, { useState, useEffect } from "react";
import moment from "moment";
import { fetchActivities } from "@5tbim/services/academic";
import Config from "@5tbim/config";

function List({ contentStyle = {}, imageStyle = {} }) {
  const [datas, setDatas] = useState([]);
  const paramsType = "academic";

  useEffect(() => {
    (async () => {
      const pagination = {
        pageIndex: 1,
        pageSize: 4,
        orderby: [["startDate", "desc"]],
      };
      const response = await fetchActivities({
        query: { acttype: paramsType },
        pagination,
      });
      if (response) {
        setDatas(response.data.rows);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      {datas && datas.length
        ? datas.map((activity) => {
            return (
              <div className="wrapcontainer__row__col" style={imageStyle}>
                <div className="contentItem">
                  <div className="contentItem__img" style={imageStyle}>
                    <a
                      rel="noopener noreferrer"
                      href={Config.AcademicDetail + "?id=" + activity.id}
                      target="_blank"
                    >
                      <img
                        alt=""
                        style={imageStyle}
                        src={activity.logo ? activity.logo : "images/fa1.png"}
                      ></img>
                    </a>
                  </div>
                  <div className="contentItem__content" style={contentStyle}>
                    <div className="contentItem__content__title">
                      <a
                        href={Config.AcademicDetail + "?id=" + activity.id}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {activity.title}
                      </a>
                    </div>
                    <div className="contentItem__content__subtitle">
                      主办方：
                      {activity.priunit}
                    </div>
                    <div className="contentItem__content__subtitle">
                      活动时间：
                      {moment(activity.startdate).format("YYYY-MM-DD")} ~{" "}
                      {moment(activity.enddate).format("YYYY-MM-DD")}
                    </div>
                    <div className="contentItem__content__subtitle">
                      活动地点：
                      {activity.address}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </React.Fragment>
  );
}

export default List;
