import merge from "webpack-merge";
import defaultConfig from "./config.default";

export default merge(defaultConfig, {
  HOST: "",
  Main: "/public/main.html",

  UserRequestList: "/public/userrequestlist.html",
  ExamPaper: "/public/exampaper.html",
  UserRequestDetail: "/public/userrequestdetail.html",

  ProjectPlanDetail: "/public/projectplandetail.html",
  TrainClasses: "/public/trainclasses.html",
  TrainClassesDetail: "/public/trainclassesdetail.html",
  ResourceDetail: "/public/resourcedetail.html",
  ResearchProjects: "/public/researchprojects.html",
  ResearchProjectDetail: "/public/researchprojectdetail.html",

  ResourceList: "/public/resourcelist.html",
  QuestionnaireDetail: "/public/questionnairedetail.html",
  QuestionnaireList: "/public/questionnairelist.html",

  AcademicDetail: "/public/academicdetail.html",
  AcademicList: "/public/academiclist.html",

  UserCenter: "/public/usercenter.html",

  ResearchSubjectPreRequest: "#/researchsubjectPreRequest", //"/researchsubjectPreRequest.html",
  MyResearchSubjectRequests: "#/myresearchsujectrequests", // "/myresearchsujectrequests.html", //申请列表
  MyResearchSubjectRequestPlan: "#/myresearchsujectrequestplan", //"/myresearchsujectrequestplan.html", //我的申请课题的规划
  MyResearchSubjectRequestPlanDetail: "#/myresearchsujectrequestplandetail", //"/myresearchsujectrequestplandetail.html",
  ProcessSuccess: "#/processsuccess", // "/processsuccess.html", //申请提交成功
  ResearchSubjectRequestPhases: "#/researchsubjectrequestphases", //"/researchsubjectrequestphases.html", //课题申请阶段
  ResearchSubjectRequestPhaseOutputEditor: "#/requestphaseoutputeditor", //"/requestphaseoutputeditor.html", //阶段输出编辑
  ResearchSubjectRequestPhaseOutputs: "#/requestphaseoutputs", //"/requestphaseoutputs.html", //阶段输出查看
  ResearchSubjectRequestPhaseOpinion: "#/requestphaseopinion", //"/requestphaseopinion.html", //阶段输出查看
  ResearchSubjectRequestConclusionReportEditor: "#/requestreporteditor", // "/requestreporteditor.html", //阶段输出查看
  ResearchSubjectRequestConclusionReport: "#/requestreport", //"/requestreport.html", //阶段输出查看

  ResearchSubjectRequestPlanDetail: "/requestplandetail.html", //立项信息
  UserCenterResearchProjectDetail: "#/usercenterprojectdetail", //"/usercenterprojectdetail.html",
  UserCenterRequestPlanDetail: "#/usercenterplandetail", // "/usercenterplandetail.html",

  MyQuestionnaires: "#/myquestionnaires", // "/myquestionnaires.html",
  PubishQuestionnaire: "#/publishquestionnaire", //"/publishquestionnaire.html",
  QuestionnaireResult: "#/questionnaireresult", //"/questionnaireresult.html",

  MyTrainClasses: "#/mytrainclasses", //"/mytrainclasses.html",
  RequestTrainer: "#/requestTrainer", //"/requestTrainer.html",
  RequestTrainClasses: "#/requestTrainClasses", // "./requestTrainClasses.html",

  MyExamRooms: "#/examrooms",
  CreateExamRoom: "#/createexamroom",
  UpdateExamRoom: "#/updateexamroom",

  MyAcademicAct: "#/myacademicact",
  MyAcademicActDetail: "#/myacademicactdetail",
});
