import React from "react";
import "./index.scss";

export default function Footer() {
  return (
    <React.Fragment>
      {/* <div className="footerTable">
        <div className="footerTable__item">
          <div>主办单位</div>
          <div>
            <a href="http://www.saiwill.com" target="_blank">
              <img
                src="images/logo1.png"
                title="采薇君华教育科技南通有限公司"
              />
            </a>
          </div>
        </div>
        <div className="footerTable__item">
          <div>合作单位</div>
          <div>
            <a href="http://www.miit.gov.cn" target="_blank">
              <img src="images/logo2.png" title="工信部人才交流中心" />
            </a>
          </div>
        </div>
        <div className="footerTable__item">
          <div>合作单位</div>
          <a href="https://www.ccli.org.cn" target="_blank">
            <img src="images/logo3.png" title="中国建设劳动学会" />
          </a>
        </div>
        <div className="footerTable__item">
          <div>支持单位</div>
          <img src="images/logo4.png" title="澳大利亚数字建筑环境协会" />
        </div>
        <div className="footerTable__item">
          <div>支持单位</div>
          <a href="http://www.bimacademy.global" target="_blank">
            <img src="images/logo5.png" title="英国BIM研究院" />
          </a>
        </div>
      </div>
      <div className="copyright">
        <img src="images/logo-cw.png" class="logo-cw" /> Copyright ©
        采薇君华教育科技有限公司 &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
        苏ICP备2023005867号-1{" "}
      </div> */}
      <footer>
        <div class="footerLogo">
          <a
            href="http://www.miit.gov.cn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="images/logo2.png" title="工信部人才交流中心" alt="" />
          </a>
          <a
            href="https://www.ccli.org.cn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="images/logo3.png" title="中国建设劳动学会" alt="" />
          </a>
          <a href="#">
            <img src="images/logo4.png" title="澳大利亚数字建筑环境协会" />
          </a>
          <a
            href="http://www.bimacademy.global"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="images/logo5.png" title="英国BIM研究院" alt="" />
          </a>
        </div>
        <hr />
        <div class="copyRight">
          <img src="images/saiwilllogo.png" class="copyLogo" alt="" />
          Copyright ©2023 采薇君华教育科技南通有限公司
          <a
            href="http://beian.miit.gov.cn"
            target="_blank"
            style={{ color: "#ffffffcc", marginLeft: 10 }}
          >
            苏ICP备2023005867号-1
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <img src="images/beian.jpg" alt="" />
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32061302000002"
            style={{ color: "#ffffffcc", marginLeft: 10 }}
          >
            苏公网安备 32061302000002号
          </a>
        </div>
      </footer>
    </React.Fragment>
  );
}
