import React from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "./carouseImage.scss";

const contentStyle = {
  height: "550px",
  color: "#fff",
  lineHeight: "300px",
  textAlign: "center",
  background: "#002147",
  position: "relative",
};

const bgContainer = {
  position: "absolute",
  left: 0,
  bottom: 0,
  right: 0,
  height: "200px",
};

const imgTitle = {
  position: "absolute",
  left: "50px",
  bottom: 20,
  color: "rgb(255, 255, 255)",
  fontSize: "36px",
  height: "180px",
};

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function () {
  return (
    <div>
      <Swiper
        autoplay={{ delay: 3000 }}
        loop={true}
        spaceBetween={50}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{
          clickable: true,
          function(index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          },
        }}
      >
        {/* <SwiperSlide>
          <div style={contentStyle}>
            <img
              style={{ width: "100%", height: "100%" }}
              src="images/slide5.jpg"
              alt=""
            ></img>
            <div className="carouseImage-bg" style={bgContainer}>
              <div style={imgTitle}></div>
            </div>
          </div>
        </SwiperSlide> */}

        {/* <SwiperSlide>
          <div style={contentStyle}>
            <img
              style={{ width: "100%", height: "100%" }}
              src="images/slide4.jpg"
              alt=""
            ></img>
            <div className="carouseImage-bg" style={bgContainer}>
              <div style={imgTitle}>拉夫堡大学MSC硕士项目推荐</div>
            </div>
          </div>
        </SwiperSlide>
      */}
        <SwiperSlide>
          <div style={contentStyle}>
            <img
              style={{ width: "100%", height: "100%" }}
              src="images/slide202311.jpg"
              alt=""
            ></img>
            <div className="carouseImage-bg" style={bgContainer}>
              <div style={imgTitle}></div>
            </div>
          </div>
        </SwiperSlide>
        {/*
        <SwiperSlide>
          <div style={contentStyle}>
            <img
              style={{ width: "100%", height: "100%" }}
              src="images/slide20232.jpg"
              alt=""
            ></img>
            <div className="carouseImage-bg" style={bgContainer}>
              <div style={imgTitle}></div>
            </div>
          </div>
        </SwiperSlide>
        */}
        <SwiperSlide>
          <div style={contentStyle}>
            <img
              style={{ width: "100%", height: "100%" }}
              src="images/slide-chart.jpg"
              alt=""
            ></img>
            <div className="carouseImage-bg" style={bgContainer}>
              <div style={imgTitle}>数字建造协同创新平台</div>
            </div>
          </div>
        </SwiperSlide>
        <div class="swiper-button-prev" slot="button-prev">
          <img src="images/left.png" alt="" />
        </div>
        <div class="swiper-button-next" slot="button-next">
          <img src="images/right.png" alt="" />
        </div>
      </Swiper>
    </div>
  );
}
