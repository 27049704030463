import Config from "@5tbim/config";
import request from "@5tbim/utils/request";
const HOST = Config.HOST;

export async function trainClassesList(params) {
  const response = request(
    `${HOST}/api/trainClasseses/list`,
    {
      method: "POST",
      body: params,
    },
    true
  );
  return response;
}

export async function detail(params) {
  const response = request(
    `${HOST}/api/trainClasseses/${params.classesId}`,
    {
      method: "GET",
    },
    true
  );
  return response;
}

export async function myTrainClasseses(params) {
  const response = request(
    `${HOST}/client/trainClasseses/headteacherClasseses`,
    {
      method: "POST",
      body: params,
    }
  );
  return response;
}

export async function online(params) {
  const response = request(
    `${HOST}/client/trainClasseses/online/${params.id}`,
    {
      method: "PUT",
      body: params,
    }
  );
  return response;
}

export async function offline(params) {
  const response = request(
    `${HOST}/client/trainClasseses/offline/${params.id}`,
    {
      method: "PUT",
      body: params,
    }
  );
  return response;
}

export async function commitAudit(params) {
  const response = request(
    `${HOST}/client/trainClasseses/commitAudit/${params.id}`,
    {
      method: "PUT",
      body: params,
    }
  );
  return response;
}

export async function getTrainer(params) {
  const response = request(`${HOST}/client/trainers/withuser`, {
    method: "GET",
  });
  return response;
}

export async function requestTrainer(params) {
  const response = request(`${HOST}/client/trainers`, {
    method: "POST",
    body: params,
  });
  return response;
}

export async function trainClassesDetail(params) {
  const response = request(`${HOST}/client/trainClasseses/${params.id}`, {
    method: "GET",
  });
  return response;
}

export async function requestTrainClasses(params) {
  const response = request(`${HOST}/client/trainClasseses`, {
    method: "POST",
    body: params,
  });
  return response;
}

export async function updateTrainClasses(params) {
  const response = request(`${HOST}/client/trainClasseses/${params.id}`, {
    method: "PUT",
    body: params,
  });
  return response;
}

export async function getGrantAreas() {
  return await request(`${HOST}/client/trainers/getGrantAreas`, {
    method: "GET",
  });
}
