import Config from "@5tbim/config";
import request from "@5tbim/utils/request";
const HOST = Config.HOST;

export async function fetch(params) {
  const response = request(
    `${HOST}/api/researchProjects/list`,
    {
      method: "POST",
      body: params,
      expirys: true,
    },
    true
  );

  return response;
}

export async function detail(params) {
  const { lang } = params;
  const url = lang
    ? `${HOST}/api/researchProjects/${params.lang}/${params.id}`
    : `/api/researchProjects/${params.id}`;
  const response = await request(
    url,
    {
      method: "GET",
    },
    true
  );
  if (response) {
    return response.data;
  }
  return null;
}

export async function submitPreRequest(params) {
  const response = request(`${HOST}/client/researchSubjectRequests`, {
    method: "POST",
    body: params,
  });
  return response;
}

export async function updatePreRequest(params) {
  const response = request(
    `${HOST}/client/researchSubjectRequests/${params.id}`,
    {
      method: "PUT",
      body: params,
    }
  );
  return response;
}

//得到所有申请信息（基本信息）
export async function ownerRequestInfo(params) {
  const response = request(`${HOST}/client/researchSubjectRequests/ownerAll`, {
    method: "GET",
  });
  return response;
}

//得到预申请信息
export async function ownerPreRequestInfo(params) {
  const response = request(
    `${HOST}/client/researchSubjectRequests/preRequest/${params.id}`,
    {
      method: "GET",
    }
  );
  return response;
}

//课题申请规划
export async function ownerRequestPlan(params) {
  const response = request(
    `${HOST}/client/researchSubjectRequests/plan/${params.id}`,
    {
      method: "GET",
    }
  );
  return response;
}

//保存课题申请（用户申请规划）
export async function saveRequestPlan(params) {
  const response = request(
    `${HOST}/client/researchSubjectRequests/setplan/${params.id}`,
    {
      method: "POST",
      body: params,
    }
  );
  return response;
}

//提交课题申请（用户申请规划）
export async function submitRequestPlan(params) {
  const response = request(
    `${HOST}/client/researchSubjectRequests/submitplan/${params.id}`,
    {
      method: "POST",
      body: params,
    }
  );
  return response;
}

//课题阶段信息
export async function subjectPhasesInfo(params) {
  const response = request(
    `${HOST}/client/researchSubjectRequests/${params.id}/phases`,
    {
      method: "GET",
    }
  );
  return response;
}

export async function getPhaseDetailWithOutputs(params) {
  const response = request(
    `${HOST}/client/researchSubjectRequestphases/${params.phaseId}/withoutputs`,
    {
      method: "GET",
    }
  );
  return response;
}

//添加阶段输出
export async function addPhaseOutput(params) {
  const response = request(
    `${HOST}/client/researchSubjectRequestphases/${params.phaseId}/phaseoutputs`,
    {
      method: "POST",
      body: params,
    }
  );
  return response;
}

//更新阶段输出
export async function updatePhaseOutput(params) {
  const response = request(
    `${HOST}/client/researchSubjectRequestphases/${params.phaseId}/phaseoutputs/${params.outputId}`,
    {
      method: "PUT",
      body: params,
    }
  );
  return response;
}

export async function deletePhaseOutput(params) {
  const response = request(
    `${HOST}/client/researchSubjectRequestphases/${params.phaseId}/phaseoutputs/${params.outputId}`,
    {
      method: "DELETE",
      body: params,
    }
  );
  return response;
}

export async function commitPhaseAudit(params) {
  const response = request(
    `${HOST}/client/researchSubjectRequestphases/${params.phaseId}/submitAudit`,
    {
      method: "PUT",
    }
  );
  return response;
}

export async function getPhaseFinishAuditOpinion(params) {
  const url = `/client/researchSubjectRequestphases/${params.phaseId}/getFinishAuditOpinion`;
  const response = await request(url, {
    method: "GET",
  });
  if (response) {
    return response.data;
  }
  return null;
}

export async function getRequestConclusionreport(params) {
  const url = `/client/researchSubjectRequests/${params.requestId}/conclusionreport`;
  const response = await request(url, {
    method: "GET",
  });
  if (response) {
    return response.data;
  }
  return null;
}

export async function saveRequestConclusionreport(params) {
  const url = `/client/researchSubjectRequests/${params.requestId}/saveconclusionreport`;
  const response = await request(url, {
    method: "POST",
    body: params,
  });
  if (response) {
    return response.data;
  }
  return null;
}

export async function submitRequestConclusionreport(params) {
  const url = `/client/researchSubjectRequests/${params.requestId}/submitconclusionreport`;
  const response = await request(url, {
    method: "POST",
    body: params,
  });
  if (response) {
    return response.data;
  }
  return null;
}

export async function getRequestBaseInfo(params) {
  const url = `/client//researchSubjectRequests/${params.requestId}/baseinfo`;
  const response = await request(url, {
    method: "GET",
  });
  if (response) {
    return response.data;
  }
  return null;
}
