import React, { useState, useEffect } from "react";
import moment from "moment";
import { trainClassesList } from "@5tbim/services/trainClasses";
import Config from "@5tbim/config";

function List({ contentStyle = {}, imageStyle = {} }) {
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    (async () => {
      const pagination = {
        pageIndex: 1,
        pageSize: 3,
        orderby: [
          ["orderIndex", "desc"],
          ["startDate", "desc"],
        ],
      };
      const response = await trainClassesList({
        pagination,
      });
      if (response) {
        setDatas(response.data.rows);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      {datas && datas.length
        ? datas.map((trainClasses) => {
            return (
              <div className="wrapcontainer__row__col">
                <div className="contentItem">
                  <div className="contentItem__img" style={imageStyle}>
                    <a
                      rel="noopener noreferrer"
                      href={
                        Config.TrainClassesDetail + "?id=" + trainClasses.id
                      }
                      target="_blank"
                    >
                      <img
                        alt=""
                        src={
                          trainClasses.attachments &&
                          trainClasses.attachments.length
                            ? trainClasses.attachments[0].uri
                            : "images/fa1.png"
                        }
                      ></img>
                    </a>
                  </div>
                  <div className="contentItem__content" style={contentStyle}>
                    <div className="contentItem__content__title">
                      <a
                        href={
                          Config.TrainClassesDetail + "?id=" + trainClasses.id
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {trainClasses.name}
                      </a>
                    </div>
                    {trainClasses.hideClientTeacherName ? null : (
                      <div className="contentItem__content__subtitle">
                        培训师：{trainClasses.headteacher.name}
                      </div>
                    )}

                    <div className="contentItem__content__subtitle">
                      开始时间：
                      {moment(trainClasses.startDate).format("YYYY-MM-DD")}
                    </div>
                    <div className="contentItem__content__subtitle">
                      结束时间：
                      {moment(trainClasses.endDate).format("YYYY-MM-DD")}
                    </div>
                    <div className="contentItem__content__desc">
                      {/* <div
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: trainClasses.desc.replace(/\n/gi, ","),
                        }}
                      ></div> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </React.Fragment>
  );
}

export default List;
