import { uuid } from "./misc";

const USER_KEY = "5tbim-app-user";
const USER_TOKEN_KEY = "5tbim-app-token";
const RECORD_INDEX = "5tbim-app-recordIndex";
const EXPIRES_KEY = "5tbim-expires";
const EXPIRES_DURATION = 6 * 60 * 60 * 1000; //4小时过期
const USER_QUESTIONNAIRE_BROWSERID = "user_questionnaire_broswerId";

function clearData() {
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(USER_TOKEN_KEY);
  localStorage.removeItem(EXPIRES_KEY);
  localStorage.removeItem(RECORD_INDEX);
}

export function setCurrentUser(loginInfo) {
  //清空原始存储
  clearData();
  const { userInfo, __token } = loginInfo;
  localStorage.setItem(USER_KEY, JSON.stringify(userInfo));
  localStorage.setItem(USER_TOKEN_KEY, __token);
  localStorage.setItem(EXPIRES_KEY, new Date().getTime() + EXPIRES_DURATION);
}

/**
 * 得到当前用户的基本信息
 */
export function getCurrentUserInfo() {
  const expiresTime = localStorage.getItem(EXPIRES_KEY);
  if (expiresTime < new Date().getTime()) {
    clearData();
    return null;
  }
  const userString = localStorage.getItem(USER_KEY);
  if (!userString) {
    return null;
  }
  return JSON.parse(userString);
}

/**
 * 获取当前用户TOKEN
 */
export function getCurrentUserToken() {
  const expiresTime = localStorage.getItem(EXPIRES_KEY);
  if (expiresTime < new Date().getTime()) {
    clearData();
    return null;
  }
  const tokenString = localStorage.getItem(USER_TOKEN_KEY);
  return tokenString;
}

export function cleanCurrentUserStore() {
  clearData();
}

export function addExerciseAnswers(requestId, exerciseAnswer) {
  const requestExercises = localStorage.getItem(requestId) || "[]";
  const requestExercisesJson = JSON.parse(requestExercises);
  let has = false;
  for (let i = 0; i < requestExercisesJson.length; i++) {
    const _exerciseAnswer = requestExercisesJson[i];
    if (_exerciseAnswer.exerciseId === exerciseAnswer.exerciseId) {
      requestExercisesJson[i] = exerciseAnswer;
      has = true;
      break;
    }
  }
  if (!has) {
    requestExercisesJson.push(exerciseAnswer);
  }
  localStorage.setItem(requestId, JSON.stringify(requestExercisesJson));
  const recordIndexs = JSON.parse(localStorage.getItem(RECORD_INDEX) || "{}");
  recordIndexs[requestId] = new Date().getTime();
  localStorage.setItem(RECORD_INDEX, JSON.stringify(recordIndexs));
}

export function getExerciseAnswers(requestId) {
  const expiresTime = localStorage.getItem(EXPIRES_KEY);
  if (expiresTime < new Date().getTime()) {
    clearData();
    return null;
  }
  return localStorage.getItem(requestId);
}

export function getUserQusetionnaireBrowserId(questionnaireId) {
  return localStorage.getItem(
    questionnaireId + "_" + USER_QUESTIONNAIRE_BROWSERID
  );
}

export function setUserQusetionnaireBrowserId(questionnaireId) {
  let broswerId = "";
  const userString = localStorage.getItem(USER_KEY);
  if (userString) {
    const userInfo = JSON.parse(userString);
    broswerId = userInfo.id;
  } else {
    broswerId = uuid(16, 16);
  }
  localStorage.setItem(
    questionnaireId + "_" + USER_QUESTIONNAIRE_BROWSERID,
    broswerId
  );
  return broswerId;
}
