import Config from "@5tbim/config";
import request from "@5tbim/utils/request";
const HOST = Config.HOST;

export async function questionnaireList(params) {
  const response = request(
    `${HOST}/api/questionnaires/list`,
    {
      method: "POST",
      body: params,
      expirys: true,
    },
    true
  );
  return response;
}

export async function myQuestionnaireList(params) {
  const response = request(
    `${HOST}/client/questionnaires/mylist`,
    {
      method: "POST",
      body: params,
      expirys: true,
    },
    true
  );
  return response;
}

export async function questionnaireDetail(params) {
  const response = request(
    `${HOST}/api/questionnaires/${params.id}`,
    {
      method: "GET",
      expirys: true,
    },
    true
  );
  return response;
}

export async function commitQuestionnaire(params) {
  const response = request(
    `${HOST}/api/questionnaires/commitQuestionnaire/${params.questionnaireId}`,
    {
      method: "POST",
      body: params,
      expirys: true,
    },
    true
  );
  return response;
}

//创建我的问卷
export async function createQuestionnaire(params) {
  const response = request(
    `${HOST}/client/questionnaires`,
    {
      method: "POST",
      body: params,
      expirys: true,
    },
    true
  );
  return response;
}

export async function commitAudit(params) {
  const response = request(
    `${HOST}/client/questionnaires/commitAudit/${params.id}`,
    {
      method: "PUT",
      body: params,
      expirys: true,
    },
    true
  );
  return response;
}

export async function online(params) {
  const response = request(
    `${HOST}/client/questionnaires/online/${params.id}`,
    {
      method: "PUT",
      body: params,
      expirys: true,
    },
    true
  );
  return response;
}

export async function offline(params) {
  const response = request(
    `${HOST}/client/questionnaires/offline/${params.id}`,
    {
      method: "PUT",
      body: params,
      expirys: true,
    },
    true
  );
  return response;
}

export async function getQuestionnaireResult(params) {
  return await request(`${HOST}/api/questionnaires/result/${params.id}`, {
    method: "GET",
  });
}
